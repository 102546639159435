<template>
  <ModernModalLayout
    size="viewport"
    title="Редактирование доступов"
    close-label="Отмена"
    :watch-for="bindings"
    dense-content
  >
    <ColumnLayout grid-template-columns="421px auto">
      <template #col-help>
        <BindingsHelp class="pa-6 pr-0" />
      </template>
      <template #col-form>
        <AccountBindings
          class="pa-6 pl-0"
          :disabled="$wait('submit')"
          :value="bindings"
          @input="handleChangeBindings"
        />
      </template>
    </ColumnLayout>

    <template #actions:append>
      <v-btn
        class="px-5"
        depressed
        color="primary"
        :disabled="$wait('submit')"
        @click="submit"
      >
        Сохранить
      </v-btn>
    </template>
  </ModernModalLayout>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import validation from '@/utils/validation';
import waitable from '@/utils/mixins/waitable';
import ModernModalLayout from '@/components/layouts/ModernModalLayout';
import BindingsHelp from './BindingsHelp';
import AccountBindings from './AccountBindings.component';
import ColumnLayout from '@/components/layouts/ColumnLayout.vue';

export default {
  components: {
    ModernModalLayout,
    BindingsHelp,
    AccountBindings,
    ColumnLayout,
  },

  mixins: [validation, waitable],

  props: {
    id: { type: Number, required: true },
    value: { type: Object, required: true },
    onSubmit: { type: Function, required: true },
  },

  data: () => ({
    bindings: {},
  }),

  created() {
    this.bindings = cloneDeep(this.value);
  },

  methods: {
    handleChangeBindings(value) {
      this.bindings = value;
    },

    async submit() {
      await this.$loadingNotify(
        this.onSubmit({ id: this.id, bindings: this.bindings }),
        'submit',
        'Не удалось изменить доступы',
        'Доступы успешно обновлены',
      )
        .then(() => this.$emit('close'))
        .catch(err => this.catchServerErrors(err, 'account'));
    },
  },
};
</script>
